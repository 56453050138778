import React, { useState, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import { useHistory } from "react-router-dom";

import "../../styles/newBlog.css";

const BlogCarousel = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useHistory();

  useEffect(() => {
    axios.get('https://paytack.com/paytackapi/blogs/')
      .then(response => setBlogs(response.data.filter(blog => blog.status === 1)));
  }, []);

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  const getReadingTime = (content) => Math.ceil(content.replace(/<[^>]*>/g, '').split(/\s+/).length / 200);

  const getExcerpt = (body) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(body, 'text/html');
    const firstDiv = htmlDoc.querySelector('div');
    const content = firstDiv ? firstDiv.textContent : '';
    return content.length > 100 ? content.substring(0, 100) + '...' : content;
  };

  const clickBlog = (blog) => {
    console.log(blog);
    navigate.push('blogs/view', {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: "John Doe",
        status: 1
      }
    })
  }



  return (
    <div className="blog-carousel py-12 ">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Latest Insights</h2>
        <Carousel responsive={responsive} infinite autoPlaySpeed={3000} containerClass="carousel-container ">
          {blogs.map((blog) => (
            <div key={blog.id} className="blog-container  ">
              <img
                className="blog-image"
                src={blog.image || "https://via.placeholder.com/300"}
                alt={blog.title}
              />
              <div className="blog-title">{blog.title}</div>
              <div className="blog-excerpt">{getExcerpt(blog.body)}</div>
              <div className="blog-footer">
                <span>{getReadingTime(blog.body)} min read</span>
                <button onClick={() => clickBlog(blog)} className="read-more-btn  bgPrimaryColor">Read more</button>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default BlogCarousel;
