import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/greenLogo.png";
import "../../styles/footer.css";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import PartnerModal from "../Home/PartnerModal";

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const partnerModal = () => {
    document.body.style.overflow = 'hidden';
    setModalIsOpen(true);
  };

  const closeModal = () => {
    document.body.style.overflow = 'auto';
    setModalIsOpen(false);
  };

  return (
    <footer className="footer">
      <div className="footer-content px-45 ">
        <div className="footer-logo">
          <img src={Logo} alt="Paytack Logo" />
          <p>Good customer loyalty in every move</p>
          <div className="social-icons">
            <a href="https://facebook.com/paytack" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://linkedin.com/company/paytack" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            <a href="https://instagram.com/paytack_loyalty" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <h3>Information</h3>
            <ul>
              <li><Link to="#" onClick={partnerModal}>Become a partner</Link></li>
              <li><Link to="/privacypolicy">Privacy Policy & GDPR</Link></li>
              <li><Link to="/termsconditions">Terms & Conditions</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Quick Links</h3>
            <ul>
              <li><Link to="/about">Home</Link></li>
              <li><Link to="/demo">For Business</Link></li>
              <li><Link to="/careers">How it works</Link></li>
              <li><Link to="/careers">Insights</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Contact us</h3>
            <div className="contact-info">
              <div>
                <p>Sweden</p>
                <p>111 36 Stockholm, Drottninggatan 86</p>
                <p>CVR: 40209751</p>
              </div>
            </div>
          </div>
       
        </div>
      </div>
      <div className="footer-bottom px-45 ">
        <div className="mail" href="mailto:info@paytack.com">info@paytack.com</div>
        <p className="pay-date">© {new Date().getFullYear()} Paytack</p>
        <p className="cop">Designed with ❤️ in Sweden</p>
      </div>
      <PartnerModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </footer>
  );
};

export default Footer;
