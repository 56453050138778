import React, { useState, useEffect, Component } from "react";
import Pulse from "react-awesome-reveal";
import{ Fade }from "react-awesome-reveal";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/SearchBar.css';

// components



export default function BlogsList() {
    const navigate = useHistory();
    const [searchItem, setSearchItem] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [blog, setBlog] = useState({});
    const menuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
      getBlogs();
    }, []);

    const [filteredBlogs, setFilteredBlogs] = useState(blogs);

    const handleInputChange = (e) => { 
      const searchTerm = e.target.value;
      setSearchItem(searchTerm)
  
      // filter the items using the blogs state
      let filteredItems = blogs.filter((blog) =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if(searchTerm===""){
        filteredItems=[];
      }
  
      setFilteredBlogs(filteredItems);
    }
  
    function getBlogs(){
      axios.get('https://paytack.com/paytackapi/blogs/').then(function(response){
        // console.log(response.data);
        let publishedBlogs = [];
        for(var i=0; i < response.data.length; i++){
          // console.log("Blog status "+response.data[i].status);
          if (response.data[i].status === 1){
            publishedBlogs.push(response.data[i]);
          }
        }
        setBlogs(publishedBlogs);
      });
    
    }

    const getReadingTime = (content) => Math.ceil(content.replace(/<[^>]*>/g, '').split(/\s+/).length / 200);

    const getExcerpt = (body) => {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(body, 'text/html');
      const firstDiv = htmlDoc.querySelector('div');
      const content = firstDiv ? firstDiv.textContent : '';
      return content.length > 100 ? content.substring(0, 100) + '...' : content;
    };

  const clickBlog = (blog) => {
    // console.log(blog);
    navigate.push('blogs/view', {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: "John Doe",
        status: 1
      }
    })
  }

  return (
    
    <>
    <section className="z-1 bg-white pt-20">
        <Row>
          <Col>
          <center>
          <div className="text-center topBlogView  pt-20"> 
            <Pulse>
              <center>
                <img
                  alt="..."
                  className=""
                  style={{
                    width: "35px",
                  }}
                  src={require("assets/img/pay.png")}
                />
                <h1 className="font-semibold text-3xl">Our Insights.</h1>
                 <div>
                    <div className="search mb-12">
                      <input 
                        type="text" 
                        value={searchItem}
                        className="searchTerm" 
                        onChange={handleInputChange}
                        placeholder='Type to search'
                      />
                      <button type="submit" className="searchButton">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                    <ul>
                      {filteredBlogs.map(blog => 
                        <li
                        onClick={() => {
                          clickBlog(blog)
                        }}>
                            {blog.title}
                        </li>
                      )}
                    </ul>
                </div>
              </center>
            </Pulse>
            </div>
            </center>
          </Col>
        </Row>
        <Row className="list-blog">
          
            {blogs.map((blog) => (
              <Col key={blog.id} className="blog-container  ">
                <img
                  className="blog-image"
                  src={blog.image || "https://via.placeholder.com/300"}
                  alt={blog.title}
                />
                <div className="blog-title">{blog.title}</div>
                <div className="blog-excerpt">{getExcerpt(blog.body)}</div>
                <div className="blog-footer">
                  <span>{getReadingTime(blog.body)} min read</span>
                  <button onClick={() => clickBlog(blog)} className="read-more-btn  bgPrimaryColor">Read more</button>
                </div>
              </Col>
            ))}
        
         
        </Row>
        </section>
    </>
  );
}
