import React from "react";
import headIcon from "../../assets/img/pay.png";
// import HowItsWorksOne from "../../assets/img/howItWorksOne.png";
import h1 from "../../assets/img/hiw1.png";
import h2 from "../../assets/img/hiw3.png";
import h3 from "../../assets/img/hiw5.png";
// import HowItsWorksTwo from "../../assets/img/howItWorkstwo.png";
import HowItsWorksThree from "../../assets/img/howItWorksThree.png";
// import OneTopLeft from "../../assets/img/oneTopLeft.png";
// import OneBottomLeft from "../../assets/img/oneBottomLeft.png";
// import TwoBottomLeft from "../../assets/img/twoBottomLeft.png";
// import TwoBottomRight from "../../assets/img/twoBottomRight.svg";
// import TwoTopLeft from "../../assets/img/twoTopLeft.png";
// import ThreeBottomRight from "../../assets/img/threeBottomRight.png";
// import ThreeMiddleRight from "../../assets/img/threeMiddleRight.png";
import ThreeTopLeft from "../../assets/img/threeTopLeft.png";
// import ThreeTopRight from "../../assets/img/threeTopRight.png";
import OneTwoConnect from "../../assets/img/oneTwoConnect.png";
import TwoThreeConnect from "../../assets/img/twoThreeConnect.png";
import '../../styles/howItWorks.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pulse from "react-awesome-reveal";
import Zoom from "react-awesome-reveal";
import Bounce from "react-awesome-reveal";

const HowItWorks = () => {
  return (
    
    <div className="how-it-works-section" id="howits">
      <Row>
            <Col sm={12} md={12} lg={12}>
            <div className="justify-center text-center flex flex-wrap">
              <Pulse>
                <div className="container-heading">
                  <img
                    className="container-heading__image"
                    src={headIcon}
                    alt="bubble"
                  />
                  <h1 className="container-heading__title">
                    How it Works
                  </h1>
                </div>
              </Pulse>
          </div>
            </Col>
          </Row>
      
      {/* <div className="container-body_main" style={{}}> */}
      
        <section className="container" style={{}}>
          
          <div className="container-body" style={{marginTop:'50px'}}>
            <Row>
              <Col sm={12} md={12} lg={4}>
                <Zoom>
                  <div className="text-center">
                    <div className="how-it-works-image-fit-container">
                      <img
                        className="how-it-works-image-fit"
                        src={HowItsWorksThree}
                        alt="how-it-works"
                      />
                      <img
                        className="one-two-connect"
                        src={OneTwoConnect}
                        alt="how-it-works"
                      />
                    </div>
                    <Bounce>
                      <div className="how-it-works-description">
                        <div className="desc_container">
                            <div className="number-indicator">
                              1
                            </div>
                            <h2 className="how-it-works-description-heading desc_heading">Link Bank</h2>
                        </div>
                        <div className="desc_text">
                            Link your bank  to the Paytack account.
                        </div>
                      </div>
                    </Bounce>
                  </div>
                </Zoom>
              </Col>
              {/* <Col sm={0} md={0} lg={1}>
              <img
                        className="one-two-connect"
                        src={OneTwoConnect}
                        alt="how-it-works"
                      />
              </Col> */}
              <Col sm={12} md={12} lg={4}>
                <Zoom>
                  <div className="text-center">
                    <div className="how-it-works-image-fit-container">
                      <img
                        className="how-it-works-image-fit"
                        src={HowItsWorksThree}
                        alt="how-it-works"
                        // style={{height: "120px"}}
                      />
                      <img
                        className="two-three-connect"
                        src={TwoThreeConnect}
                        alt="how-it-works"
                      />
                    </div>
                    <Bounce bottom>
                      <div className="how-it-works-description">
                        <div className="desc_container">
                            <div className="number-indicator">
                              2
                            </div>
                            <h2 className="how-it-works-description-heading desc_heading">Collect cashback</h2>
                        </div>
                        <div className="desc_text">
                            Get cash back for every qualified purchase automatically.
                        </div>
                      </div>
                    </Bounce>
                  </div>
                </Zoom>
              </Col>
              {/* <Col sm={0} md={0} lg={1}> <img
                        className="two-three-connect"
                        src={TwoThreeConnect}
                        alt="how-it-works"
                      /></Col> */}
              <Col sm={12} md={12} lg={4}>
                <Zoom>
                  <div className="text-center">
                    <div className="how-it-works-image-fit-container">
                      <img
                        className="how-it-works-image-fit"
                        src={HowItsWorksThree}
                        alt="how-it-works"
                      />
                    
                  
                    </div>
                    <Bounce right>
                      <div className="how-it-works-description">
                      <div className="desc_container">
                            <div className="number-indicator">
                              3
                            </div>
                            <h2 className="how-it-works-description-heading desc_heading">Redeem cashback</h2>
                        </div>
                        <div className="desc_text">
                            Redeem your collected cash bank balance on your next purchase. 
                        </div>
                      </div>
                    </Bounce>
                  </div>
                </Zoom>
              </Col>
            </Row>
        
        {/* <div className="how-it-works-container-body">
          <div style={{width:window.innerWidth < 700 ? '100%':'33.33%'}}>
            {window.innerWidth < 700 && <div>
              <div className="number-indicator" style={{ position: 'static', margin:'auto', marginTop:'50px' }}>1</div>
              <h2 className="how-it-works-description-heading" style={{marginBottom:'20px'}}>Sign-up</h2>
            </div>}
            
          </div>

          <div style={{width:window.innerWidth < 700 ? '100%':'33.33%'}}>
            {window.innerWidth < 700 && <div>
              <div className="number-indicator" style={{ position: 'static', margin:'auto' , marginTop:'50px' }}>2</div>
              <h2 className="how-it-works-description-heading" style={{marginBottom:'20px'}}>Upload the bill</h2>
            </div>}
            
          </div>

          <div style={{width:window.innerWidth < 700 ? '100%':'33.33%'}}>
            {window.innerWidth < 700 && <div>
              <div className="number-indicator" style={{ position: 'static', margin:'auto', marginTop:'50px'  }}>3</div>
              <h2 className="how-it-works-description-heading" style={{marginBottom:'100px'}}>Earn Cashback</h2>
            </div>}*/}
            

          
        </div> 
      </section>
      {/* </div> */}
    </div>
  );
};

export default HowItWorks;
